import { ajax } from 'rxjs/ajax';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

// Make sure the endpoint has not been updated
const mailApi =
  'https://7loac7ldfeyecnehropsztalpi0roakm.lambda-url.us-east-1.on.aws/';
function onSubmit(event) {
  event.preventDefault();

  const form = event.currentTarget;
  const formData = new FormData(form);
  const message = `
Name: ${formData.get('name')}
Email: ${formData.get('email')}
Subject: ${formData.get('subject')}
Message: ${formData.get('message')}`;
  const payload = { message };

  const props = {
    body: JSON.stringify(payload),
    url: mailApi,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  ajax(props)
    .pipe(
      catchError((error) => {
        const alert = document.querySelector('.alert-danger');
        const message = alert.querySelector('.message');

        alert.classList.remove('hidden');
        message.textContent =
          'We apologize for the inconvenience, but there was an error sending this message';

        console.log({ error });
        return of({
          message: error.message,
          status: error.status,
        });
      })
    )
    .subscribe((data) => {
      if (data.status === 200) {
        const alert = document.querySelector('.alert-success');
        const message = alert.querySelector('.message');

        alert.classList.remove('hidden');
        message.textContent =
          'Thank you for your message. I read them all and I will get back to you soon.';
        form.reset();
      }
    });
}

window.onload = function onLoad() {
  const form = document.querySelector('[name=contact]');

  if (form) {
    form.onsubmit = onSubmit;
  }
};
