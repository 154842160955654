function debounce(func, wait) {
  let timeout;

  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeout);

    timeout = setTimeout(function () {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

// Taken from mdbook
// The strategy is as follows:
// First, assign a value to each word in the document:
//  Words that correspond to search terms (stemmer aware): 40
//  Normal words: 2
//  First word in a sentence: 8
// Then use a sliding window with a constant number of words and count the
// sum of the values of the words within the window. Then use the window that got the
// maximum sum. If there are multiple maximas, then get the last one.
// Enclose the terms in <b>.
function makeTeaser(body, terms) {
  const TERM_WEIGHT = 40;
  const NORMAL_WORD_WEIGHT = 2;
  const FIRST_WORD_WEIGHT = 8;
  const TEASER_MAX_WORDS = 30;

  const stemmedTerms = terms.map(function (w) {
    return elasticlunr.stemmer(w.toLowerCase());
  });
  const weighted = []; // contains elements of ['word', weight, index_in_document]

  // split in sentences, then words
  const sentences = body.toLowerCase().split('. ');

  let termFound = false;
  let index = 0;

  for (let i in sentences) {
    const words = sentences[i].split(' ');
    let value = FIRST_WORD_WEIGHT;

    for (let j in words) {
      const word = words[j];

      if (word.length > 0) {
        for (let k in stemmedTerms) {
          if (elasticlunr.stemmer(word).startsWith(stemmedTerms[k])) {
            value = TERM_WEIGHT;
            termFound = true;
          }
        }
        weighted.push([word, value, index]);
        value = NORMAL_WORD_WEIGHT;
      }

      index += word.length;
      index += 1;  // ' ' or '.' if last word in sentence
    }

    index += 1;  // because we split at a two-char boundary '. '
  }

  if (weighted.length === 0) {
    return body;
  }

  const windowWeights = [];
  const windowSize = Math.min(weighted.length, TEASER_MAX_WORDS);

  // We add a window with all the weights first
  let curSum = 0;

  for (let i = 0; i < windowSize; i++) {
    curSum += weighted[i][1];
  }
  windowWeights.push(curSum);

  for (let i = 0; i < weighted.length - windowSize; i++) {
    curSum -= weighted[i][1];
    curSum += weighted[i + windowSize][1];
    windowWeights.push(curSum);
  }

  // If we didn't find the term, just pick the first window
  let maxSumIndex = 0;

  if (termFound) {
    let maxFound = 0;

    // backwards
    for (let i = windowWeights.length - 1; i >= 0; i--) {
      if (windowWeights[i] > maxFound) {
        maxFound = windowWeights[i];
        maxSumIndex = i;
      }
    }
  }

  const teaser = [];

  let startIndex = weighted[maxSumIndex][2];

  for (let i = maxSumIndex; i < maxSumIndex + windowSize; i++) {
    const word = weighted[i];
    if (startIndex < word[2]) {
      // missing text from index to start of `word`
      teaser.push(body.substring(startIndex, word[2]));
      startIndex = word[2];
    }

    // add <em/> around search terms
    if (word[1] === TERM_WEIGHT) {
      teaser.push('<b>');
    }
    startIndex = word[2] + word[0].length;
    teaser.push(body.substring(word[2], startIndex));

    if (word[1] === TERM_WEIGHT) {
      teaser.push('</b>');
    }
  }
  teaser.push('…');
  return teaser.join('');
}

function formatSearchResultItem(item, terms) {
  return '<div class="search-results__item">'
    + `<a href='${item.ref}'>${item.doc.title}</a>`
    + `<div>${makeTeaser(item.doc.body, terms)}</div>`
    + '</div>';
}

function initSearch() {
  const $searchInput = document.getElementById('search');
  const $searchResultsItems = document.querySelector('.search-results__items');
  const MAX_ITEMS = 10;

  const options = {
    bool: 'AND',
    fields: {
      title: {boost: 2},
      body: {boost: 1},
    }
  };
  const index = elasticlunr.Index.load(window.searchIndex);

  let currentTerm = '';

  $searchInput.addEventListener('keyup', debounce(function() {
    const term = $searchInput.value.trim();

    if (term === currentTerm || !index) {
      return;
    }

    $searchResultsItems.innerHTML = '';

    if (term === '') {
      return;
    }

    const results = index.search(term, options);

    currentTerm = term;

    for (let i = 0; i < Math.min(results.length, MAX_ITEMS); i++) {
      const item = document.createElement('li');

      item.className = 'py-4';
      item.innerHTML = formatSearchResultItem(results[i], term.split(' '));
      $searchResultsItems.appendChild(item);
    }
  }, 150));

  window.addEventListener('click', function() {
    $searchInput.value = '';
    $searchResultsItems.innerHTML = '';
  });
}


if (document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)) {
  initSearch();
} else {
  document.addEventListener('DOMContentLoaded', initSearch);
}
